<template>
 <div class="footer fooBottom">
    <div style="height:200px" class="container box">
      <div class="item">
        <p>Copy@2023 {{site.site_name}} 版权所有 <a style="color:#fff" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{site.site_icp}}</a></p>
        <p>地址:{{site.site_address}}</p>
        <p>邮编:{{site.post_code}} 电话:{{site.site_mobile}}</p>
      </div>
    </div>
 </div>
</template>

<script>
// import { getSite } from '../api/site'
export default {
  data () {
    return {
      site: [] // 网站信息
    }
  },
  async created () {
    // 判断vuex中有没有数据，避免多次发请求
    if (!this.$store.state.commin.site.lenght) {
      // 网站信息
      await this.$store.dispatch('commin/site')
      // console.log(1111)
    }
    // 网站信息的数据
    this.site = this.$store.state.commin.site[0]
  }
}
</script>

<style lang = "less" scoped>
 .footer{
  margin-top: 30px;
  height: 200px;
  background-color:#FF0000;
  .box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    display: flex;
    width: 620px;
    height: 120px;
    /* background-color: yellow; */
    color: #fff;
    p{
      font-size: 18px;
    }
  }
 }
</style>
