import { GetHonor } from '@/api/honor'
export default {
  namespaced: true,
  state: {
    honor_item: {} // 荣誉资质信息
  },
  mutations: {
    sethonor (state, res) {
      state.honor_item = res
    }
  },
  actions: {
    async honor ({ commit }, item) {
      const res = await GetHonor(item)
      // console.log(res)
      commit('sethonor', res)
    }
  },
  getters: {}
}
