import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 配置一级路由
  { path: '/index.html', component: () => import('../views/HomePage.vue') },
  { path: '/', redirect: '/index.html' },
  { path: '/about.html', component: () => import('../views/WalkIntoSansheng.vue') },
  { path: '/honor.html', component: () => import('../views/HonorQualification.vue') },
  { path: '/news.html', component: () => import('../views/NewsAsk.vue') },
  { path: '/industry.html', component: () => import('../views/ImforItem.vue') },
  { path: '/cases.html', component: () => import('../views/EngineeringCase.vue') },
  { path: '/jobs.html', component: () => import('../views/RecruitTalent.vue') },
  { path: '/contact.html', component: () => import('../views/ContactUs.vue') },
  { path: '/newsdetail.html', component: () => import('../views/NewsDetail.vue') },
  { path: '/honordetail.html', component: () => import('../views/HonorDetail.vue') },
  { path: '/industrydetail.html', component: () => import('../views/ImforDteail.vue') },
  { path: '/casesdetail.html', component: () => import('../views/EnginDeatil.vue') },
  { path: '/jobsdetail.html', component: () => import('../views/RecruitDetail.vue') }
]

const router = new VueRouter({
  routes
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
