import { getSite } from '@/api/site'
import { GetHeadNav } from '@/api/homepages'
import { GetBgc } from '@/api/about'

export default {
  namespaced: true,
  state: {
    site: [], // 网站公司信息
    headnavItem: [], // 头部导航信息
    bgcs: [], // 背景图信息
    // 控制页面是否正在显示loading
    isShowLoading: false
  },
  mutations: {
    // 修改state中的信息 网站信息
    site (state, res) {
      state.site = res
    },
    headnav (state, res) {
      state.headnavItem = res
    },
    bgc (state, res) {
      state.bgcs = res
    },
    setShow (state, data) {
      state.isShowLoading = data
    }
  },
  actions: {
    // 网站信息数据
    async site ({ commit }) {
      const res = await getSite()
      // console.log(res)
      commit('site', res)
    },

    // 头部导航信息
    async headnav ({ commit }) {
      const res = await GetHeadNav()
      // console.log(res)
      // 按order 排序   is_play是否显示？？？
      const res1 = res.sort((a, b) => a.order - b.order)
      commit('headnav', res1)
    },

    // 页面背景图
    async bgc ({ commit }) {
      const res = await GetBgc()
      // console.log(res)
      const res1 = res.sort((a, b) => a.id - b.id)
      commit('bgc', res1)
    }
  },
  getters: {}
}
