
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
let loadingCount = 0
const addLoading = () => {
  // 开始请求时，添加数量，并且设置为显示loading
  loadingCount = loadingCount + 1
  store.commit('commin/setShow', true)
}
const isCloseLoading = () => {
  // 请求完成或者请求失败时,减少正在请求的数量,并且判断是否还有未完成的请求,如果没有了,则设置为隐藏loading
  loadingCount--
  if (loadingCount === 0) {
    store.commit('commin/setShow', false)
  }
}

// 创建axios实例
const request = axios.create({
  baseURL: 'https://api.sansjz.com/',
  timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  addLoading()
  return config
}, function (error) {
  // 对请求错误做些什么
  isCloseLoading()
  Message.error('网络异常，请稍后再试')
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么,延迟0.01秒关闭，避免因请求快而出现loading 闪烁的情况
  setTimeout(() => {
    isCloseLoading()
  }, 10)
  return response.data // 解套
}, function (error) {
  // 对响应错误做点什么
  isCloseLoading()
  Message.error('服务器请求失败，请联系管理员')
  return Promise.reject(error)
})

export default request
