<template>
   <div class="left">
        <div class="top">
          <!-- 挖坑 父组件传一个a标签包裹的标题文本 -->
           <slot name="header"></slot>
        </div>
        <div class="bottom">
          <ul>
            <!-- 可以传进一个数组 遍历 -->
            <li v-for="(item,index) in leftnavItem" :key="item.id">
              <a href="javascript: void(0)" :class="{titleColor:checked == index}" @click="toggle(item.path)">
                <span>{{item.name}}</span>
                <i class="el-icon-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      checked: 0, // 与下标对应 显示选中状态
      leftnavItem: [] // 导航
    }
  },
  async created () {
    // 跳转新闻详情对应选中新闻资讯状态
    if (this.$route.path === '/newsdetail.html') {
      this.checked = 2
    }
    // 跳转荣誉资质
    if (this.$route.path === '/honordetail.html') {
      this.checked = 1
    }
    // 跳行业详情
    if (this.$route.path === '/industrydetail.html') {
      this.checked = 3
    }
    // 跳工程的详情
    if (this.$route.path === '/casesdetail.html') {
      this.checked = 4
    }
    // 跳招贤的详情
    if (this.$route.path === '/jobsdetail.html') {
      this.checked = 5
    }
    if (!this.$store.state.commin.headnavItem.length) {
      // 头部导航信息
      await this.$store.dispatch('commin/headnav')
    }
    // 导航 ,没有显示 网站首页
    this.leftnavItem = this.$store.state.commin.headnavItem.slice(1)
    // console.log(this.leftnavItem)

    // 页面跳转对应头部导航选中状态
    this.leftnavItem.forEach((item, index) => {
      // console.log(this.$route.path, item.path)
      // 路由路径是没有 . 的 ，把后台给的路径前面的 . 截掉，与之对应
      if (this.$route.path === item.path.slice(1)) {
        this.checked = index
      }
    })
    // 子传父
    this.$emit('about_tit', this.leftnavItem[0].name) // 传三圣
    this.$emit('honor_tit', this.leftnavItem[1].name) // 传荣誉资质
    this.$emit('honordetail_tit', this.leftnavItem[1].name, this.leftnavItem[1].path) // 传荣誉资质详情
    this.$emit('new_tit', this.leftnavItem[2].name) // 传新闻资讯
    this.$emit('newdetail_tit', this.leftnavItem[2].name, this.leftnavItem[2].path) // 传新闻资讯详情
    this.$emit('imfor_tit', this.leftnavItem[3].name) // 传行业信息
    this.$emit('imfordetail_tit', this.leftnavItem[3].name, this.leftnavItem[3].path) // 传行业信息详情
    this.$emit('eng_tit', this.leftnavItem[4].name) // 传工程信息
    this.$emit('engdetail_tit', this.leftnavItem[4].name, this.leftnavItem[4].path) // 传工程信息的详情
    this.$emit('job_tit', this.leftnavItem[5].name) // 传招贤信息
    this.$emit('jobdetail_tit', this.leftnavItem[5].name, this.leftnavItem[5].path) // 传招贤信息的详情
    this.$emit('contact_tit', this.leftnavItem[6].name) // 传联系我们信息
  },
  methods: {
    toggle (path) {
      this.$router.push(path)
    }
  }

}
</script>

<style lang="less" scoped>
.left{
    margin: 30px 30px;
    width: 275px;
    height: 475px;
    // background-color: yellow;
    border: 1px solid #EBEAEA;
    .top{
      height: 65px;
      background: #FF0000;
      border: 1px solid #FFFFF0;
      a{
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 65px;
        font-size: 23px;
        font-family: MicrosoftYaHei;
        color: #FCF8F8;
      }
    }
    .bottom{
      padding:20px 30px;
      ul li {
        a{
          color: #747171;
          font-size: 18px;
          line-height: 50px;
          display: block;
          height: 50px;
          // background-color: skyblue;
          border-bottom:  1px solid #E4E1E1;
         i{
          line-height: 50px;
          float: right;
         }
         &:hover{
          color: #FF0000;
         }
        }
      }
      //切换时显示的颜色
      .titleColor{
        color: #FF0000;
      }
    }
  }
</style>
