import { GetJobs } from '@/api/jobs'
export default {
  namespaced: true,
  state: {
    jobs_item: {} // 招贤纳士信息
  },
  mutations: {
    setjobs (state, res) {
      state.jobs_item = res
    }
  },
  actions: {
    async jobs ({ commit }, item) {
      const res = await GetJobs(item)
      // console.log(res)
      commit('setjobs', res)
    }
  },
  getters: {}
}
