import { GetNews } from '@/api/news'
export default {
  namespaced: true,
  state: {
    news_item: {} // 新闻信息
  },
  mutations: {
    setnews (state, res) {
      state.news_item = res
    }

  },
  actions: {
    // 新闻
    async news ({ commit }, item) {
      const res = await GetNews(item)
      // console.log(res)
      commit('setnews', res)
    }
  },
  getters: {}
}
