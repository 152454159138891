import { GetInduItem } from '@/api/industry'
export default {
  namespaced: true,
  state: {
    indu_item: {} // 行业信息
  },
  mutations: {
    setindu (state, res) {
      state.indu_item = res
    }
  },
  actions: {
    // 行业信息
    async getindu ({ commit }, item) {
      const res = await GetInduItem(item)
      // console.log(res)
      commit('setindu', res)
    }
  },
  getters: {}
}
