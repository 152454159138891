import { GetAbout } from '@/api/about'
export default {
  namespaced: true,
  state: {
    about_item: [] // 走进三圣的信息
  },
  mutations: {
    setabout (state, res) {
      state.about_item = res
    }
  },
  actions: {
    async getabout ({ commit }) {
      const res = await GetAbout()
      // console.log(res)
      commit('setabout', res)
    }
  },
  getters: {}
}
