import request from '@/utils/request'

// 获取手机验证码
export const GetCode = (item) => request.get('verifications/sms_codes/', { params: item })

// 手机号码验证
export const UserPhone = (item) => request.post('verifications/login/', item)

// 查询公司信息
export const GetCompany = (item) => request.get('company/company/', { params: item })
