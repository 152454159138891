import { GetCode, UserPhone, GetCompany } from '@/api/black'
export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 获取短信码
    async getcode ({ commit }, item) {
      const res = await GetCode(item)
      // console.log(res)
      return res
    },
    // 验证用户
    async userphone ({ commit }, item) {
      const res = await UserPhone(item)
      // console.log(res)
      return res
    },
    // 查询公司
    async getcompany ({ commit }, item) {
      const res = await GetCompany(item)
      // console.log(res)
      return res
    }
  },
  getters: {}
}
