import { GetCase } from '@/api/cases'
export default {
  namespaced: true,
  state: {
    cases: {} // 工程案例
  },
  mutations: {
    setcase (state, res) {
      state.cases = res
    }
  },
  actions: {
    // 工程案例信息
    async case ({ commit }, item) {
      const res = await GetCase(item)
      // console.log(res)
      commit('setcase', res)
    }
  },
  getters: {}
}
