import Vue from 'vue'
import Vuex from 'vuex'
import commin from './modules/commin'
import home from './modules/home'
import cases from './modules/cases'
import about from './modules/about'
import honor from './modules/honor'
import news from './modules/news'
import industry from './modules/industry'
import jobs from './modules/jobs'
import contact from './modules/contact'
import black from './modules/black'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    commin,
    home,
    about,
    honor,
    news,
    industry,
    jobs,
    contact,
    cases,
    black
  }
})

export default store
