<template>
  <div class="headNav" >
    <div class="top"></div>
    <!-- 公司logo名称 -->
    <div class="item container">
      <img style="width:43px;height:63px" class="log" :src="site.site_logo" alt="">
      <div class="siteName">
        <h2>{{site.site_name}}</h2>
        <span>{{site.en_name}}</span>
      </div>
      <div class="phone">
        <img style="width:38px;height:38px"  src="../assets/images/phone.png" alt="">
        <span>{{site.site_mobile}}</span>
      </div>
    </div>
    <!-- 头部导航 -->
    <div class="nav">
      <ul class="container">
        <li v-for="(item,index) in headnavItem" :key="item.id">
          <a href="javascript:void(0);" :class="{text1:checkIndex==index}" @click="contact(index,item.path)" v-if="item.is_display">{{item.name}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      checkIndex: 0,
      site: [], // 网站信息数据
      headnavItem: [] // 头部导航信息
    }
  },
  async  created () {
    // 跳转新闻详情对应选中新闻资讯状态
    if (this.$route.path === '/newsdetail.html') {
      this.checkIndex = 3
    }
    // 跳转荣誉资质
    if (this.$route.path === '/honordetail.html') {
      this.checkIndex = 2
    }
    // 跳行业详情
    if (this.$route.path === '/industrydetail.html') {
      this.checkIndex = 4
    }
    // 跳工程的详情
    if (this.$route.path === '/casesdetail.html') {
      this.checkIndex = 5
    }
    // 跳招贤的详情
    if (this.$route.path === '/jobsdetail.html') {
      this.checkIndex = 6
    }
    // 判断vuex中有没有数据，避免多次发请求
    if (!this.$store.state.commin.site.length || !this.$store.state.commin.headnavItem.length) {
      // 网站信息
      await this.$store.dispatch('commin/site')

      // 头部导航信息
      await this.$store.dispatch('commin/headnav')
    }
    // 网站信息的数据
    this.site = this.$store.state.commin.site[0]
    // 头部导航
    this.headnavItem = this.$store.state.commin.headnavItem

    // 页面跳转对应头部导航选中状态
    this.headnavItem.forEach((item, index) => {
      // console.log(this.$route.path, item.path)
      // 路由路径是没有 . 的 ，把后台给的路径前面的 . 截掉，与之对应
      if (this.$route.path === item.path.slice(1)) {
        this.checkIndex = index
      }
    })
  },

  methods: {
    // 点击触发切换状态
    contact (index, path) {
      this.checkIndex = index
      this.$router.push(path)
    }

  }

}
</script>

<style lang="less" scoped>
 .headNav{
    .top{
    height: 7px;
    background: #FF0000;
  }
    .item{
      display: flex;
      .log{
        margin-left: 143px;
        margin-top: 14px;
      }
      .siteName{
        text-align: center;
        margin-left: 12px;
        margin-top: 14px;
        color: #FF0000;
        h2{
          font-weight: 400;
          font-size: 30px;
        }
      }
      .phone{
        display: flex;
        text-align: center;
        margin-left: 334px;
        img{
          margin-right: 13px;
          margin-top: 19px;
        }
        span{
          display: block;
          margin-top:23px;
          font-size: 23px;
          margin-top: 20px;
          color: #FF0000;
        }
      }
    }
    .nav{
      height: 41px;
      background: #FF0000;
      ul{
        line-height: 41px;
        display: flex;
        font-size: 15px;
        justify-content: space-around;
        li{
          width: 65px;

          a{
            text-align: center;
            display: block;
            height: 38px;
            color: #fff;
            &:hover{
            border-bottom:2px solid #fff;
            }

          }
        }
      }
    }
  // 头部导航样式
  /* 先实验看效果 后面动态选择 */
    .text1{
      border-bottom:2px solid #fff;
    }
 }
 /* 当屏幕小于1200时 */
@media screen and (max-width:1200px){
  /* 顶部条 */
  .headNav{
    .top{
      width: 1200px !important;
    }
    /* 头部导航条 */
    .nav{
      width: 1200px !important;
    }
  }
}
</style>
