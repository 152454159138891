import { getBanners } from '@/api/homepages'
export default {
  namespaced: true,
  state: {
    banners: [] // 轮播图
  },
  mutations: {
    // 轮播图
    banners (state, res) {
      state.banners = res
    }
  },
  actions: {
    // 轮播图
    async banners ({ commit }) {
      const res = await getBanners()
      // console.log(res)
      // 轮播图只取5个
      commit('banners', res.slice(-5))
    }
  },
  getters: {}
}
