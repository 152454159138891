import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Carousel, CarouselItem, Message, Button, Row, Form, FormItem, Input, Col, Dialog } from 'element-ui' // 按需引入elementui组件库
import 'element-ui/lib/theme-chalk/index.css' // elementui的样式
import HeadNav from './components/HeadNav.vue'
import FooterNav from './components/FooterNav.vue'
import LeftNav from './components/LeftNav.vue'
import './assets/style/css/base.css'

Vue.config.productionTip = false

// 全局注册组件
Vue.component('HeadNav', HeadNav)
Vue.component('FooterNav', FooterNav)
Vue.component('LeftNav', LeftNav)

// 按需引入
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Button)
Vue.use(Row)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Col)
Vue.use(Dialog)
// Vue.use(Loading.directive)
Vue.prototype.$message = Message // 按需引入，挂载到原型上

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
